<template>
  <v-container fluid class="d-flex justify-center" v-if="!isLoading">
    <div class="d-flex flex-column" style="min-width: 80%">
      <v-alert dense border="left" outlined type="warning" v-if="!is_logged_in">
        You must be <strong>logged in</strong> to post on the boards.
      </v-alert>
      <h1>Torchship Community Forums</h1>
      <div
        class="d-flex flex-column"
        v-for="category in board_categories"
        :key="category"
      >
        <v-sheet rounded outlined class="pl-2 pt-1 pb-1 mb-2 mt-8 font-h6">
          {{ category }}
        </v-sheet>
        <forum-topic-card
          v-for="topic in board_topics.filter((bt) => bt.category == category)"
          :key="topic.title"
          :topic="topic"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import store from "@/store";

import { FETCH_BOARD_TOPICS } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

import ForumTopicCard from "@/components/Boards/ForumTopicCard.vue";

export default Vue.extend({
  name: "Boards",
  components: { ForumTopicCard },
  computed: {
    ...mapGetters([
      "board_topics",
      "board_categories",
      "isLoading",
      "is_logged_in",
    ]),
  },
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([store.dispatch(FETCH_BOARD_TOPICS)]).then(() => {
      store.commit(END_LOADING);
    });
  },
});
</script>
