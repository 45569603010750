<template>
  <div class="topic-container mt-2 mb-2">
    <v-sheet shaped class="topic-body pa-2">
      <div class="text-h5">
        <router-link
          :to="`/boards/topic/${topic.id}`"
          class="text-h5 font-button"
        >
          {{ topic.title }}
          <v-icon v-if="topic.locked">mdi-lock</v-icon>
        </router-link>
      </div>
      <div class="text-subtitle-1 font-weight-light">
        {{ topic.description }}
      </div>
    </v-sheet>
    <v-sheet
      rounded
      elevation="6"
      class="topic-details blue-grey darken-4 font-weight-light pa-2 text-caption"
      style="opacity: 0.8"
    >
      <template v-if="isNaN(topic.latest_poster_id) || !topic.latest_poster_id">
        No Recent Posts
      </template>
      <template v-else>
        <div>
          Post by {{ topic.latest_poster }} on
          {{ new Date(topic.latest_thread_date * 1000).friendlyString() }}
        </div>
        <router-link :to="`/boards/thread/${topic.latest_thread_id}`">
          {{ topic.latest_thread_title }}
        </router-link>
      </template>
    </v-sheet>
    <div class="topic-actions text-h6">
      <span>{{ topic.thread_count }}</span> threads
    </div>
  </div>
</template>

<script>
export default {
  name: "ForumTopicCard",
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.topic-container {
  display: grid;
  grid-template-columns: 3fr 1.2fr;
  grid-template-rows: 1em 1fr 2em;
}

.topic-details {
  grid-row: 1 / span 2;
  grid-column: 2;
  margin-right: 2em;
}

.topic-body {
  grid-row: 2 / span 2;
  grid-column: 1 / 3;
}

.topic-actions {
  grid-row: 3;
  grid-column: 2;
  margin-right: 2em;
  text-align: right;
}
</style>
